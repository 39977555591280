import React from 'react';
import { Dropdown, Menu } from 'antd';
import { GlobalOutlined, DownOutlined } from '@ant-design/icons';
import { setLocale } from '@/locales';
import { useIntl } from 'react-intl';

const LanguageSetting = props => {
  const intl = useIntl();
  const menu = (
    <Menu onClick={({ key }) => setLocale(key)}>
      <Menu.Item key="en-US">English</Menu.Item>
      <Menu.Item key="zh-CN">中文</Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu} arrow={true} {...props}>
      <span>
        <GlobalOutlined />
        {intl.formatMessage({ id: 'layout.lang' })}
        <DownOutlined
          style={{ marginLeft: 4, fontSize: 10, color: '#9f9f9f' }}
        />
      </span>
    </Dropdown>
  );
};
export default LanguageSetting;
