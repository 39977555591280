import React, { useCallback, useEffect, useReducer } from 'react';
import { Table } from 'antd';
import { handleError } from '@/utils/handleError';
import { REQUEST_STATUS } from '@/utils/constants';
import { createNextState } from '@reduxjs/toolkit';
import { createAction } from '@/utils/reducer';
import { showTableTotal } from '@/utils/utils';
import cloneDeep from 'lodash/cloneDeep';

const initialState = {
  status: '',
  list: [],
  pagination: {
    defaultCurrent: 1,
    defaultPageSize: 20,
    pageSize: 20,
    current: 1,
    total: 0,
    showTotal: showTableTotal, //(total, range) => `${range[0]}-${range[1]} of ${total} items`,
  },
};

const getInitialState = pageSize => {
  const state = cloneDeep(initialState);
  state.pagination.defaultPageSize = pageSize;
  state.pagination.pageSize = pageSize;
  return state;
};

const reducer = (state, action) => {
  switch (action.type) {
    case REQUEST_STATUS.IDLE:
      return createNextState(state, draft => {
        draft.status = action.type;
        draft.pagination.current = 1;
      });
    case REQUEST_STATUS.PENDING:
      return createNextState(state, draft => {
        draft.status = action.type;
      });
    case REQUEST_STATUS.SUCCEEDED:
      return createNextState(state, draft => {
        draft.status = action.type;
        draft.list = action.payload.res.items;
        draft.pagination.total = action.payload.res.total;
        draft.pagination.current = action.payload.pagination.current;
        draft.pagination.pageSize = action.payload.pagination.pageSize;
      });
    case REQUEST_STATUS.FAILED:
      handleError(action.payload.e, action.payload.errormsg);
      return createNextState(state, draft => {
        draft.status = action.type;
      });
    default:
      return state;
  }
};

const PagedTable = ({
  columns,
  params,
  request,
  errormsg,
  pageSize,
  ...props
}) => {
  //---------------------- state
  const [state, dispatch] = useReducer(
    reducer,
    pageSize ? getInitialState(pageSize) : initialState
  );

  //---------------------- effects
  const fetchData = useCallback(
    (query, pagination) => {
      dispatch(createAction(REQUEST_STATUS.PENDING));
      request({
        page: pagination.current,
        size: pagination.pageSize,
        ...query,
      })
        .then(res => {
          dispatch(createAction(REQUEST_STATUS.SUCCEEDED, { res, pagination }));
        })
        .catch(e => {
          dispatch(createAction(REQUEST_STATUS.FAILED, { e, errormsg }));
        });
    },
    [request, errormsg]
  );

  // 每次渲染时判断是否重新获取数据
  useEffect(() => {
    if (state.status === REQUEST_STATUS.IDLE && fetchData) {
      dispatch(createAction(REQUEST_STATUS.PENDING));
      fetchData(params, state.pagination);
    }
  });

  // 查询参数变化时重新获取数据
  useEffect(() => {
    dispatch(createAction(REQUEST_STATUS.IDLE));
  }, [params]);

  //---------------------- events
  // (pagination, filters, sorter, extra)=>{}
  const handleTableChange = (pagination, filters, sorter, extra) => {
    if (pagination.pageSize !== state.pagination.pageSize) {
      pagination.current = 1;
    }
    fetchData(params, pagination);
  };

  //---------------------- view
  return (
    <Table
      loading={state.status === REQUEST_STATUS.PENDING}
      columns={columns}
      dataSource={state.list}
      pagination={state.pagination}
      onChange={handleTableChange}
      {...props}
    />
  );
};
export default React.memo(PagedTable);
