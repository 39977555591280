import React from 'react';
import { Button } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import styles from './HttpError.module.less';
import { useSelector } from 'react-redux';
import { AUTH_STATUS, selectStatus } from '@/features/auth/authSlice';

const ErrorContent = ({ code, home }) => {
  const intl = useIntl();
  return (
    <div>
      <div className={styles.wrapper}>
        <div className={styles.code}>{code}</div>
        <div className={styles.title}>
          {intl.formatMessage({ id: `p.errorpage.${code}` })}
        </div>
      </div>
      {home !== false && (
        <div className={styles.link}>
          <Link to={home || '/'}>
            <Button type="primary" ghost icon={<HomeOutlined />}>
              {intl.formatMessage({ id: `p.errorpage.home` })}
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
};

const HttpError = props => {
  const authstatus = useSelector(selectStatus);
  if (props.layout && authstatus === AUTH_STATUS.SUCCEEDED) {
    return (
      <props.layout>
        <ErrorContent code={props.code} home={props.home} />
      </props.layout>
    );
  } else {
    return <ErrorContent code={props.code} home={props.home || '/'} />;
  }
};
export default HttpError;
