import React from 'react';
import './PageHeader.less';

const PageHeader = ({ title }) => {
  return (
    <div className="content-header">
      <div className="content-header-title">{title}</div>
    </div>
  );
};
export default PageHeader;
