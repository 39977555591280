const messages = {
  'menu.setting': 'Settings',
  'menu.menu': 'Menus',
  'menu.role': 'Roles',
  'menu.message': 'Mail Messages',
  'menu.appconfig': 'Configurations',
  'menu.user': 'Users',
  'menu.home': 'Home',
  'menu.report': 'Report',
  'menu.excelimportres': 'Excel Import Result',
  'menu.checkitems': 'Check Items',
  'menu.masterdata': 'Master Data',
  'menu.othermasterdata': 'Other Master Data',
  'menu.stepandmrp': 'Step And MRP',
  'menu.mailmodel': 'Mail Model',
  'menu.promanagerbackup': 'Project Manager Backup',
};
export default messages;
