import error from './zh-CN/error';
import menu from './zh-CN/menu';
import pages from './zh-CN/pages';

const zhCN = {
  'common.loading': '加载中...',
  'common.search': '查询',
  'common.clear': '清除',
  'common.add': '添加',
  'common.import': '导入',
  'common.edit': '编辑',
  'common.permissions': '权限',
  'common.delete': '删除',
  'common.back': '返回',
  'common.save': '保存',
  'common.cancel': '取消',
  'common.submit': '提交',
  'common.reset': '重置',
  'common.close': '关闭',
  'common.delcfm': '确认要删除{value}吗？',
  'common.thisitem': '这条记录',
  'common.deleting': '正在删除...',
  'common.yes': '是',
  'common.no': '否',
  'common.unknown': '未知',
  'common.nocontent': '无内容',
  'common.loadmore': '加载更多',
  'common.success': '成功',
  'common.example': '例如：{value}',
  'layout.appname': 'Project Execution Management',
  'layout.lang': '多语言',
  'layout.signout': '退出',
  'common.exportexcel': '导出 Excel',
  'common.copy.item': '复制Item信息',
  'common.see': '查看',
  'common.SetDisplayField': '设置显示字段',
  'common.Sure': '确定',
  ...error,
  ...menu,
  ...pages,
};
export default zhCN;
