const messages = {
  'p.login.error': '登录失败，请重新登陆',
  'p.login.welcome': '欢迎使用',
  'p.login.employee': 'ABB 员工',
  'p.login.employeetip': '使用ABB邮箱登录',
  'p.login.customer': 'ABB 客户',
  'p.login.customertip': '查询我的订单',
  'p.errorpage.403': '访问受限',
  'p.errorpage.404': '页面不存在',
  'p.errorpage.home': '返回首页',
  'p.role.name': '角色',
  'p.role.plural': '角色',
  'p.user.name': '用户',
  'p.user.plural': '用户',
  'p.user.f.email': '邮箱',
  'p.user.f.name': '姓名',
  'p.user.f.roles': '角色',
  'p.user.s.kw': '姓名，邮箱...',
  'p.user.s.roleph': '所有角色',
  'p.orderpoint.pdpstart': 'PDP生产开始',
  'p.orderpoint.pdpfinish': 'PDP生产完成',
  'p.orderpoint.pdpbilling': 'PDP成品报关',
  'p.orderpoint.blwarehouse': 'BL成品入库',
  'p.orderpoint.bldeparture': 'BL成品运输(出发)',
  'p.orderpoint.blarrival': 'BL成品运输(到达)',
  'p.contact.pointph': '所有节点',
  'p.contact.emailph': '邮箱...',
  'p.contact.f.ContactPoint': '订单节点',
  'p.contact.f.PlantCode': '工厂',
  'p.contact.f.CompanyCode': '公司',
  'p.contact.f.PhaseOwnerEmail': '节点联系人',
  'p.contact.f.PhaseOwnerName': '联系人名称',
  'p.contact.f.Escalation1': '1st Escalation Owner',
  'p.so.s.customerph': '客户名称...',
  'p.so.s.projectph': '项目编号...',
  'p.so.s.robotph': '机器人型号...',
  'p.so.noorder': '没有订单',
  'p.so.nobtorder': '没有BT订单',
  'p.so.selectorder': '请选择订单',
  'p.so.sotitle': '合同详细数据',
  'p.so.dntitle': '物流信息',
  'p.so.createdate': '创建日期',
  'p.so.releasedate': '发布日期',
  'p.so.sales': '销售',
  'p.so.pm': '项目经理',
  'p.so.plandeparture': '计划出发',
  'p.so.actualdeparture': '实际出发',
  'p.so.planarrival': '计划到达',
  'p.so.actualarrival': '实际到达',
  'p.so.question': '提问',
  'p.so.f.RobotType': '机器人型号',
  'p.so.f.Quantity': '数量',
  'p.so.f.SalesUnit': '单位',
  'p.dn.tobedelivered': '待发货',
  'p.dn.ondelivery': '运输中',
  'p.dn.delivered': '已签收',
  'p.dn.plandeparture': '计划出发',
  'p.dn.actualdeparture': '实际出发',
  'p.dn.planarrival': '计划到达',
  'p.dn.actualarrival': '实际到达',
  'p.dn.ActualGIDate': 'SAP发货时间',
  'p.dn.tip': '系统仅显示6个月内的物流信息',
  'p.dn.shipmentno': '物流单号',
  'p.bt.notstart': '未开始',
  'p.bt.inprod': '生产中',
  'p.bt.delivered': '已交付',
  'p.bt.ontime': 'On Time',
  'p.bt.timegap': 'Time Gap',
  'p.bt.delay': 'Delay',
  'p.bt.plandate': '计划日期',
  'p.bt.actualdate': '实际日期',
  'p.commun.f.ProjectNumber': '项目',
  'p.commun.f.QuestionType': '订单节点',
  'p.commun.f.dnorder': '相关订单',
  'p.commun.f.RobotType': '机器人型号',
  'p.commun.f.PlannedDate': '计划日期',
  'p.commun.f.ActualDate': '实际日期',
  'p.commun.f.PhaseOwner': '联系人',
  'p.commun.f.QuestionContent': '问题',
  'p.commun.f.RequestUserName': '提问人',
  'p.commun.f.RequestOn': '提问时间',
  'p.commun.f.ClosedUserName': '关闭人',
  'p.commun.f.ClosedOn': '关闭时间',
  'p.commun.f.Answered': '答复状态',
  'p.commun.dnhelp': '查询物流相关问题时请输入项目编号',
  'p.commun.btlabel': 'BT订单',
  'p.commun.dnlabel': '物流',
  'p.commun.pendinglabel': '处理中',
  'p.commun.closedlabel': '已关闭',
  'p.commun.questiontitle': '问题',
  'p.commun.answertitle': '答复',
  'p.commun.addaction': 'Add Action',
  'p.commun.closed': '已关闭',
  'p.commun.closecfm': 'Are you sure to close this action?',
  'p.answer.f.DelayReason': 'Delay Reason',
  'p.answer.f.Description': 'Description',
  'p.answer.f.UpdatePlanDate': 'Update Plan Date',
  'p.answer.f.Actions': 'Actions',
  'p.answer.f.ActionContent': 'Action',
  'p.answer.f.ActionOwner': 'Owner',
  'p.answer.f.TargetDate': 'Target Date',
  'p.answer.f.ClosedTime': 'Closed On',
  'p.customerorder.pagetitle': '订单查询',
  'p.customerorder.s.customer': '客户代码',
  'p.customerorder.s.project': '项目编号',
  'p.customerorder.searchtip': '请输入客户代码和项目编号进行查询',
  'p.customerorder.noorder': '找不到订单',
  'p.role.code': '编码',
  'p.role.name': '名称',
  'p.role.remark': '备注',
  'p.menu.addmenu': '添加菜单',
  'p.menu.addsubmenu': '添加子菜单',
  'p.menu.delete.msg': '是否确定删除该菜单?',
  'p.menu.addfield': '添加字段',
  'p.menu.code': '编码',
  'p.menu.name': '名称',
  'p.menu.title': '标题',
  'p.menu.url': '链接',
  'p.menu.order': '排序',
  'p.menu.visible': '是否可见',
  'p.menu.enable': '是否可用',
  'p.menu.description': '描述',
  'p.checklist.s.kw': '中文描述,英文描述...',
  'p.checkitems.addmenu': '添加检查项类别',
  'p.checkitems.addsubmenu': '添加检查项数据',
  'p.checkitems.name': '中文名称',
  'p.checkitems.enName': '英文名称',
  'p.checkitems.checktype': '检查项类型',
  'p.checkitems.addsubmenu.msg': '请选择父级项',
  'p.checkitems.addsubmenu.second.msg': '最多只能添加二级子项.',
  'p.masterdata.addmenu': '添加基础数据类别',
  'p.masterdata.addsubmenu': '添加基础数据',
  'p.delete.msg': '是否确定删除?',
  'p.home.mytask.title': '我的待办项目',
  'p.home.excelimport.res.title': '项目导入结果',
  'p.home.excelimport.table.name': '文件名',
  'p.home.excelimport.table.time': '创建时间',
  'p.home.excelimport.table.result': '结果',
  'p.home.excelimport.table.action': '操作',
  'p.home.mytask.form.handoverstartdate': '交接开始日期',
  'p.home.mytask.form.handoverenddate': '交接结束日期',
  'p.home.mytask.form.customercompany': '客户公司名称',
  'p.home.mytask.form.projectname': '项目名称',
  'p.home.mytask.form.saleorder': '销售订单',
  'p.home.mytask.form.designnum': '设计号',
  'p.home.mytask.form.commitmentstartdate': '工厂承诺日期起',
  'p.home.mytask.form.commitmentenddate': '工厂承诺日期止',
  'p.home.mytask.form.actualstartdate': '工厂实际日期起',
  'p.home.mytask.form.actualenddate': '工厂实际日期止',
  'p.home.mytask.form.invoicestartdate': '开票日期起',
  'p.home.mytask.form.invoiceenddate': '开票日期止',
  'p.home.mytask.form.finishstartdate': '完工日期起',
  'p.home.mytask.form.finishenddate': '完工日期止',
  'p.home.mytask.form.projectmanager': '项目经理',
  'p.home.mytask.form.projectstate': '状态',
  'p.home.mytask.form.excelimport': 'Excel导入',
  'p.home.mytask.modal.excelbutton': '请选择Excel文件',
  'p.home.mytask.menu.excelmodel': 'Excel模板下载',
  'p.home.mytask.menu.excelimport': 'Excel批量导入',
  'p.excelimportres.form.keyword': '销售订单号',
  'p.addproject.title': '添加项目',
  'p.addproject.edit.title': '编辑项目',
  'p.addproject.btn.add': '获取项目',
  'p.addproject.tabs.title.commommsg': '通用信息',
  'p.addproject.tabs.title.itemmsg': 'Item信息',
  'p.home.mytask.form.btn.updateitemstate': '批量设置Item状态',
  'p.home.mytask.columns.saleorder': '销售订单',
  'p.home.mytask.columns.projectname': '项目名称',
  'p.home.mytask.columns.handovertime': '项目交接时间',
  'p.home.mytask.columns.customercompanyname': '客户公司名称',
  'p.home.mytask.columns.promiseddeliverytime': '工厂的承诺出货日期',
  'p.home.mytask.columns.sale': '销售',
  'p.report.columns.saleorder': '销售订单',
  'p.report.columns.projectmanager': '项目经理',
  'p.report.columns.sale': '销售',
  'p.report.columns.projectname': '项目名称',
  'p.report.columns.handovertime': '项目交接时间',
  'p.report.columns.customercompanyname': '客户公司名称',
  'p.report.columns.promiseddeliverytime': '工厂承诺的出货日期',
  'p.report.columns.actualshipmentdate': '实际工厂出货日期',
  'p.report.columns.invoicedate': '开票日期',
  'p.report.columns.factory': '工厂',
  'p.report.columns.profitcenter': '利润中心',
  'p.report.columns.guaranteeperiod': '质保期',
  'p.excelimportres.columns.saleorder': '销售订单',
  'p.excelimportres.columns.status': '同步状态',
  'p.excelimportres.columns.synchronousmessages': '同步消息',
  // -------------------------------------------------------
  'p.addproject.checkItemsColumns.checkItemType': '所属类别',
  'p.addproject.checkItemsColumns.name': '检查项',
  'p.addproject.checkItemsColumns.status': '状态',
  'p.addproject.checkItemsColumns.remark': '备注',
  'p.addproject.checkItemsColumns.status.placeholder': '请选择状态',
  'p.addproject.checkItemsColumns.remark.placeholder': '请输入备注',
  'p.addproject.itemsColumns.commitmentDate': '工厂承诺的出货日期',
  'p.addproject.itemsColumns.deliveryDate': '物流条款的交货日期',
  'p.addproject.itemsColumns.invoiceDate': '开票日期',
  'p.addproject.itemsColumns.customerCode': '客户代码',
  'p.addproject.itemsColumns.projectStatus': '项目状态',
  'p.addproject.testColumns.testType': '测试类型',
  'p.addproject.testColumns.testDate': '测试日期',
  'p.addproject.testColumns.remark': '备注',
  'p.addproject.testColumns.testDate.placeholder': '请选择测试日期',
  'p.addproject.testColumns.remark.placeholder': '请输入备注',
  'p.addproject.supplyColumns.arriveData': '到货日期',
  'p.addproject.supplyColumns.isRrive': '是否到货',
  'p.addproject.supplyColumns.remark': '备注',
  'p.addproject.commommsg.basicinformation': '基础信息',
  'p.addproject.commommsg.saleorder': '销售订单',
  'p.addproject.commommsg.projectname': '项目名称',
  'p.addproject.commommsg.designnum': '设计号',
  'p.addproject.commommsg.handovertime': '项目交接时间',
  'p.addproject.commommsg.logisticsterms': '物流条款',
  'p.addproject.commommsg.customerinformation': '客户信息',
  'p.addproject.commommsg.customercompanyname': '客户公司名称',
  'p.addproject.commommsg.customercontact': '客户联系人',
  'p.addproject.commommsg.customercontact.placeholder': '请输入客户联系人',
  'p.addproject.commommsg.projectrelatedperson': '项目相关人',
  'p.addproject.commommsg.projectmanager': '项目经理',
  'p.addproject.commommsg.sale': '销售',
  'p.addproject.commommsg.electricalengineer': '电气工程师',
  'p.addproject.commommsg.electricalengineer.placeholder': '请输入电气工程师',
  'p.addproject.commommsg.mechanicalengineer': '机械工程师',
  'p.addproject.commommsg.mechanicalengineer.placeholder': '请输入机械工程师',
  'p.addproject.commommsg.checkitems': '检查项',
  'p.addproject.commommsg.btn.settingcheckitems': '设置检查项',
  'p.addproject.commommsg.btn.classificationsociety': '船级社',
  'p.addproject.commommsg.btn.classificationsociety.placeholder': '请选择船级社',
  'p.addproject.itemmsg.btn.updateitemstate': '批量设置Item状态',
  'p.addproject.modal.eidtitems.title': '编辑Item',
  'p.addproject.modal.eidtitems.iteminfo': 'Item信息',
  'p.addproject.modal.eidtitems.promiseddeliverytime': '工厂承诺的出货日期',
  'p.addproject.modal.eidtitems.deliveryDate': '物流条款的交货日期',
  'p.addproject.modal.eidtitems.voltage': '电压',
  'p.addproject.modal.eidtitems.actualshipmentdate': '实际工厂出货日期',
  'p.addproject.modal.eidtitems.invoicedate': '开票日期',
  'p.addproject.modal.eidtitems.guaranteeperiod': '质保',
  'p.addproject.modal.eidtitems.power': '功率',
  'p.addproject.modal.eidtitems.seriesnumber': '序列号',
  'p.addproject.modal.eidtitems.purchaseordernum': '采购订单号',
  'p.addproject.modal.eidtitems.logisticsterms': '物流条款',
  'p.addproject.modal.eidtitems.designfreezingpoint': '设计冻结点',
  'p.addproject.modal.eidtitems.designfreezingpoint.placeholder': '请输入设计冻结点',
  'p.addproject.modal.eidtitems.certificate': '证书',
  'p.addproject.modal.eidtitems.certificate.placeholder': '请选择证书',
  'p.addproject.modal.eidtitems.color': '颜色',
  'p.addproject.modal.eidtitems.color.placeholder': '请输入颜色',
  'p.addproject.modal.eidtitems.packingrequirement': '包装要求',
  'p.addproject.modal.eidtitems.packingrequirement.placeholder': '请选择包装要求',
  'p.addproject.modal.eidtitems.projectfilepath': '项目文件路径',
  'p.addproject.modal.eidtitems.addfilepath': '添加路径',
  'p.addproject.modal.eidtitems.IprocessECN/ECRurl': 'iProcess ECN/ECR 链接',
  'p.addproject.modal.eidtitems.checkitem.name': '检查项',
  'p.addproject.modal.eidtitems.settingcheckitems': '设置检查项',
  'p.addproject.modal.eidtitems.starconnectiontype': '星接类型',
  'p.addproject.modal.eidtitems.starconnectiontype.placeholder': '请选择星接类型',
  'p.addproject.modal.eidtitems.witnesstestinformation': '见证测试信息',
  'p.addproject.modal.eidtitems.customersupplyinformation': '客供件信息',
  'p.addproject.modal.eidtitems.addcustomersupplies': '添加客供件',
  'p.addproject.modal.eidtitems.po.placeholder': '请输入PO',
  'p.addproject.modal.eidtitems.arriveDate': '到货日期',
  'p.addproject.modal.eidtitems.arriveDate.placeholder': '请选择到货日期',
  'p.addproject.modal.eidtitems.isArrive': '是否到货',
  'p.addproject.modal.eidtitems.isArrive.placeholder': '请选择是否到货',
  'p.addproject.modal.eidtitems.remark.placeholder': '请输入备注',
  //-------------------------------------------------------------------
  'p.stepandmrp.addstep': '添加步骤',
  'p.stepandmrp.addmrp': '添加MRP',
  'p.checkitems.addstep.msg': '请选择步骤',
  'p.checkitems.addmrp.second.msg': '只能基于步骤添加MRP.',
  'p.mytask.tabs.title.associatedSoPro': '已关联SO项目',
  'p.mytask.tabs.title.noAssociatedSoPro': '未关联SO项目',
  'p.home.mytask.form.itemnum': 'Item 号',
  'p.home.mytask.form.reservationnum': 'Reservation 号',
  'p.addproject.modal.eidtitems.itemnum.placeholder': '请输入Item Num',
  'p.addproject.modal.eidtitems.remark.placeholder': '请输入备注',
  'p.addproject.modal.eidtitems.productionplan': '生产计划',
  'p.addproject.planColumns.planStep': '生产步骤',
  'p.addproject.planColumns.planStartTime': '计划开始时间',
  'p.addproject.planColumns.planEndTime': '计划结束时间',
  'p.addproject.planColumns.actualStartTime': '实际开始时间',
  'p.addproject.planColumns.actualEndTime': '实际结束时间',
  'p.home.mytask.form.filedesc': '文件描述',
  'p.home.mytask.form.filepath': '文件地址',
  'p.home.mytask.form.networth': '净值',
  'p.home.mytask.form.currency': '币种',
  'p.home.mytask.form.number': '数量',
  'p.home.mytask.form.Pre-deliverydate': '预交付日期',
  'p.addproject.modal.eidtitems.TVNum': '电机型号',
  'p.addproject.modal.eidtitems.TVNum.place': '请输入电机型号',
  'p.home.mytask.form.currency.place': '请选择币种',
  'p.home.mytask.form.Pre-deliverydate.place': '请选择预交付日期',
  'p.home.mytask.form.projectstate.place': '请选择状态',
  'p.addproject.modal.eidtitems.addWitnessTest': '添加见证测试',
  'p.addproject.testColumns.isCompleted': '否完成测试',
  'p.home.mytask.form.contractNumber': '合同号',
  'p.addproject.commommsg.projectname.Request': '项目名称是必填项.',
  'p.addproject.commommsg.reservationNum.Request': 'Reservation 号是必填项.',
  'p.addproject.commommsg.projectManager.Request': '项目经理是必填项.',
  'p.addproject.commommsg.itemNum.Request': 'Item Num是必填项.',
  'p.addproject.commommsg.projectState.Request': '状态是必填项.',
  'p.mailmodel.name': '名称',
  'p.mailmodel.title': '标题',
  'p.mailmodel.modelContext': '邮件模板',
  'p.addproject.info.checkitem.msg': '项目检查项的状态为必填项',
  'p.addproject.item.checkitem.msg': 'Item检查项的状态为必填项',
  'p.addproject.modal.eidtitems.createdBy': '创建者',
  'p.addproject.modal.eidtitems.createdTime': '创建时间',
  'p.addproject.testColumns.isSo': '是否关联SO',
  'p.addproject.testColumns.IsMarine': '是否有船级社',
  'p.addproject.itemsColumns.abacuscode': 'ABACUS代码',
  'p.addproject.modal.eidtitems.payment': '付款条件',
  'p.home.mytask.form.industry': '行业',
  'p.home.mytask.form.application': '应用',
  'p.report.columns.netRenminbi': 'ORDER REV(CNY)',
  'report.waring.column.isnull': '请设置要显示的字段',
  'p.addproject.commommsg.items.Request': '请添加Item数据',
  'p.addproject.modal.delete.title': '删除',
  'p.addproject.modal.deleteInfo': '删除项目',
  'p.addproject.modal.deleteItem': '仅删除Item',
  'p.addproject.modal.delete.message': '删除数据后将不能恢复，请确认操作 !',
  'p.addproject.modal.itemnum.message': "Item Num不匹配！",
  'p.addproject.uploadTitle': '上传文件',
  'p.addproject.Customerdrawings': '客户图纸',
  'p.addproject.certificate': '证书',
  'p.addproject.QualityDocumentation': '质量文档',
  'p.addproject.other': '其他',
  'p.filedmodelform.title': '报表自定义显示',
  'p.filedmodelform.name': '名称',
  'p.filedmodelform.isDefault': '是否默认',
  'p.filedmodelform.operation': '操作',
  'p.ReportFieldForm.title': '请选择项目列表中需要显示的字段',
  'p.report.columns.item': '条目',
  'p.report.columns.default.show.fiels': '默认显示字段',
  'p.addproject.modal.eidtitems.uploadfile': '上传文件',
  'p.addproject.modal.eidtitems.addprojectfilepath': '查看项目文件',
  'p.projectfilepathdetail.title': '项目文件路径',
  'p.report.columns.customerContact': '合同号',
  'p.backup.form.status': '状态',
  'p.backup.form.startdate': '开始时间',
  'p.backup.form.enddate': '结束时间',
  'p.backup.form.effectivedate': '生效时间',
  'p.backup.form.backuppmname': '备份项目经理',
  'p.home.mytask.form.sapUpdateTime': 'SAP同步时间',
};
export default messages;
