import { lazy } from 'react';
//import SideMenu from '../components/SideMenu';
import LayoutPlain from '../components/LayoutPlain';

// auth: 验证权限
// menu: 菜单，如果为空与auth一致
// anonymous: 默认false
// layout: 默认LayoutDefault
// exact: 是否精确匹配
// routes: 子路由
const routes = [
  {
    auth: 'home',
    path: '/home/mytask',
    component: lazy(() => import('../features/home/Mytask')),
  },
  {
    auth: 'home',
    path: '/home/excelimportres',
    component: lazy(() => import('../features/home/ExcelImportRes')),
  },
  {
    auth: 'report',
    path: '/home/addproject/:addtype/:id/:itemid',
    component: lazy(() => import('../features/home/AddProject')),
  },
  {
    auth: 'report',
    path: '/home/projectfilepathdetail/:id',
    component: lazy(() => import('../features/home/ProjectFilePathDetail')),
  },
  {
    auth: 'report',
    path: '/report/reportlist',
    component: lazy(() => import('../features/report/Reportlist')),
  },
  {
    auth: 'checkitems',
    path: '/masterdata/checkitems',
    component: lazy(() => import('../features/masterdata/checkitems/CheckList')),
  },
  {
    auth: 'othermasterdata',
    path: '/masterdata/othermasterdata',
    component: lazy(() => import('../features/masterdata/othermasterdata/MasterDataList')),
  },
  {
    auth: 'user',
    path: '/setting/user',
    component: lazy(() => import('../features/setting/user/UserList')),
  },
  {
    auth: 'menu',
    path: '/setting/menu',
    component: lazy(() => import('../features/setting/menu/MenuList')),
  },
  {
    auth: 'role',
    path: '/setting/role',
    component: lazy(() => import('../features/setting/role/RoleList')),
  },
  {
    auth:'stepandmrp',
    path:'/setting/stepandmrp',
    component: lazy(() => import('../features/setting/stepAndMrp/Index')),
  },
  {
    auth:'mailmodel',
    path:'/setting/mailmodel',
    component: lazy(() => import('../features/setting/mailModel/Index')),
  },
  {
    auth: 'promanagerbackup',
    path: '/setting/promanagerbackup',
    component: lazy(() => import('../features/setting/promanagerbackup/BackupList')),
  },
  {
    path: '/signin',
    component: lazy(() => import('../features/auth/SignIn')),
    anonymous: true,
    layout: false,
  },
  /*
  {
    path: '/signin-internal-use-only',
    component: lazy(() => import('../features/auth/SignInTest')),
    anonymous: true,
    layout: false,
  },*/
];

function standardize(items) {
  for (let item of items) {
    // menu
    if (item.auth && !item.menu) item.menu = item.auth;
    // anonymous
    item.anonymous = item.anonymous === true ? true : false;
    // exact
    if (item.exact === undefined)
      item.exact = !(item.routes && item.routes.length > 0);
    if (item.routes) standardize(item.routes);
  }
}

standardize(routes);

export default routes;
