import error from './en-US/error';
import menu from './en-US/menu';
import pages from './en-US/pages';

const enUS = {
  'common.loading': 'Loading...',
  'common.search': 'Search',
  'common.clear': 'Clear',
  'common.add': 'Add',
  'common.import': 'Import',
  'common.edit': 'Edit',
  'common.permissions': 'Permissions',
  'common.delete': 'Delete',
  'common.back': 'Back',
  'common.save': 'Save',
  'common.cancel': 'Cancel',
  'common.submit': 'Submit',
  'common.reset': 'Reset',
  'common.close': 'Close',
  'common.delcfm': 'Are you sure to delete {value}?',
  'common.thisitem': 'this item',
  'common.deleting': 'Deleting...',
  'common.yes': 'Yes',
  'common.no': 'No',
  'common.unknown': 'Unknown',
  'common.nocontent': 'No Content',
  'common.loadmore': 'Load More',
  'common.success': 'Success',
  'common.example': 'e.g. {value}',
  'layout.appname': 'Project Execution Management',
  'layout.lang': 'Languages',
  'layout.signout': 'Sign Out',
  'common.exportexcel': 'Export Excel',
  'common.copy.item': 'Copy Item Message',
  'common.see': 'Detail',
  'common.SetDisplayField': 'Set Display Field',
  'common.Sure': 'Confirm',
  ...error,
  ...menu,
  ...pages,
};
export default enUS;
