const messages = {
  'p.login.error':
    "Something went wrong and we can't sign you in right now. Please try again.",
  'p.login.welcome': 'Welcome to',
  'p.login.employee': 'ABB Employee',
  'p.login.employeetip': 'Sign in with ABB email',
  'p.login.customer': 'ABB Customer',
  'p.login.customertip': 'Query my orders',
  'p.errorpage.403': 'FORBIDDEN',
  'p.errorpage.404': 'PAGE NOT FOUND',
  'p.errorpage.home': 'BACK TO HOME PAGE',
  'p.role.name': 'role',
  'p.role.plural': 'roles',
  'p.user.name': 'user',
  'p.user.plural': 'users',
  'p.user.f.email': 'Email',
  'p.user.f.name': 'Name',
  'p.user.f.roles': 'Roles',
  'p.user.s.kw': 'name, email...',
  'p.user.s.roleph': 'All roles',
  'p.orderpoint.pdpstart': 'Production Start',
  'p.orderpoint.pdpfinish': 'Production Completed',
  'p.orderpoint.pdpbilling': 'PDP Custom Clearance',
  'p.orderpoint.blwarehouse': 'BL in-Warehouse',
  'p.orderpoint.bldeparture': 'BL Delivery(Departure)',
  'p.orderpoint.blarrival': 'BL Delivery(Arrival)',
  'p.contact.pointph': 'All points',
  'p.contact.emailph': 'email...',
  'p.contact.f.ContactPoint': 'Contact Point',
  'p.contact.f.PlantCode': 'Plant',
  'p.contact.f.CompanyCode': 'Company',
  'p.contact.f.PhaseOwnerEmail': 'Phase Owner Email',
  'p.contact.f.PhaseOwnerName': 'Phase Owner Display Name',
  'p.contact.f.Escalation1': '1st Escalation Owner',
  'p.so.s.customerph': 'Customer Name...',
  'p.so.s.projectph': 'Project Number...',
  'p.so.s.robotph': 'Robot Type...',
  'p.so.noorder': 'No Order',
  'p.so.nobtorder': 'No bt orders',
  'p.so.selectorder': 'PLEASE SELECT AN ORDER',
  'p.so.sotitle': 'Contract Details',
  'p.so.dntitle': 'Delivery Information',
  'p.so.createdate': 'Created Date',
  'p.so.releasedate': 'Release Date',
  'p.so.sales': 'Sales',
  'p.so.pm': 'PM',
  'p.so.plandeparture': 'Planned Departure',
  'p.so.actualdeparture': 'Actual Departure',
  'p.so.planarrival': 'Planned Arrival',
  'p.so.actualarrival': 'Actual Arrival',
  'p.so.question': 'Question',
  'p.so.f.RobotType': 'Robot Type',
  'p.so.f.Quantity': 'Quantity',
  'p.so.f.SalesUnit': 'Unit',
  'p.dn.tobedelivered': 'To be delivered',
  'p.dn.ondelivery': 'On delivery',
  'p.dn.delivered': 'Delivered',
  'p.dn.plandeparture': 'Planned Departure',
  'p.dn.actualdeparture': 'Actual Departure',
  'p.dn.planarrival': 'Planned Arrival',
  'p.dn.actualarrival': 'Actual Arrival',
  'p.dn.ActualGIDate': 'SAP Actual GI date',
  'p.dn.tip': '系统仅显示6个月内的物流信息',
  'p.dn.shipmentno': 'Shipement No.',
  'p.bt.notstart': 'Not Started',
  'p.bt.inprod': 'In Production',
  'p.bt.delivered': 'Delivered',
  'p.bt.ontime': 'On Time',
  'p.bt.timegap': 'Time Gap',
  'p.bt.delay': 'Delay',
  'p.bt.plandate': 'Planned Date',
  'p.bt.actualdate': 'Actual Date',
  'p.commun.f.ProjectNumber': 'Project',
  'p.commun.f.QuestionType': 'Order Point',
  'p.commun.f.dnorder': 'Related Order',
  'p.commun.f.RobotType': 'Robot Type',
  'p.commun.f.PlannedDate': 'Planned Date',
  'p.commun.f.ActualDate': 'Actual Date',
  'p.commun.f.PhaseOwner': 'Send to',
  'p.commun.f.QuestionContent': 'Question',
  'p.commun.f.RequestUserName': 'Requested by',
  'p.commun.f.RequestOn': 'Requested on',
  'p.commun.f.ClosedUserName': 'Closed by',
  'p.commun.f.ClosedOn': 'Closed on',
  'p.commun.f.Answered': 'Answered',
  'p.commun.dnhelp':
    'Please enter project number when inquiring delivery questions',
  'p.commun.btlabel': 'BT Order',
  'p.commun.dnlabel': 'Delivery',
  'p.commun.pendinglabel': 'Pending',
  'p.commun.closedlabel': 'Closed',
  'p.commun.questiontitle': 'Question',
  'p.commun.answertitle': 'Answer',
  'p.commun.addaction': 'Add Action',
  'p.commun.closed': 'Closed',
  'p.commun.closecfm': 'Are you sure to close this action?',
  'p.answer.f.DelayReason': 'Delay Reason',
  'p.answer.f.Description': 'Description',
  'p.answer.f.UpdatePlanDate': 'Update Plan Date',
  'p.answer.f.Actions': 'Actions',
  'p.answer.f.ActionContent': 'Action',
  'p.answer.f.ActionOwner': 'Action Owner',
  'p.answer.f.TargetDate': 'Target Date',
  'p.answer.f.ClosedTime': 'Closed On',
  'p.customerorder.pagetitle': 'Orders',
  'p.customerorder.s.customer': 'Customer Code',
  'p.customerorder.s.project': 'Project Number',
  'p.customerorder.searchtip':
    'Please enter the customer code and project number to search ',
  'p.customerorder.noorder': 'NO ORDER FOUND',
  'p.role.code': 'Code',
  'p.role.name': 'Name',
  'p.role.remark': 'Remark',
  'p.menu.addmenu': 'Add Menu',
  'p.menu.addsubmenu': 'Add Sub Menu',
  'p.menu.delete.msg': 'Are you sure to delete this menu?',
  'p.menu.addfield': 'Add field',
  'p.menu.code': 'Code',
  'p.menu.name': 'Name',
  'p.menu.title': 'Title',
  'p.menu.url': 'Url',
  'p.menu.order': 'Order',
  'p.menu.visible': 'Visible',
  'p.menu.enable': 'Enable',
  'p.menu.description': 'Description',
  'p.checklist.s.kw': 'Chinese Description,English Description...',
  'p.checkitems.addmenu': 'Add a check item category',
  'p.checkitems.addsubmenu': 'Add check item data',
  'p.checkitems.name': 'Name',
  'p.checkitems.enName': 'En Name',
  'p.checkitems.checktype': 'Check Item Type',
  'p.checkitems.addsubmenu.msg': 'Please select a item first.',
  'p.checkitems.addsubmenu.second.msg': 'There is only a second-order relationship.',
  'p.masterdata.addmenu': 'Add Master Data Category',
  'p.masterdata.addsubmenu': 'Adding Master Data',
  'p.delete.msg': 'Are you sure to delete?',
  'p.home.mytask.title': 'My to-do list',
  'p.home.excelimport.res.title': 'Project import result',
  'p.home.excelimport.table.name': 'File Name',
  'p.home.excelimport.table.time': 'CreatedDate',
  'p.home.excelimport.table.result': 'Result',
  'p.home.excelimport.table.action': 'Action',
  'p.home.mytask.form.handoverstartdate': 'Handover Start Date',
  'p.home.mytask.form.handoverenddate': 'Handover End Date',
  'p.home.mytask.form.customercompany': 'Customer Name',
  'p.home.mytask.form.projectname': 'Project Name',
  'p.home.mytask.form.saleorder': 'SO',
  'p.home.mytask.form.designnum': 'Design Num',
  'p.home.mytask.form.commitmentstartdate': 'Confirmed EXW Date Start',
  'p.home.mytask.form.commitmentenddate': 'Confirmed EXW Date End',
  'p.home.mytask.form.actualstartdate': 'Actual EXW Date Start',
  'p.home.mytask.form.actualenddate': 'Actual EXW Date End',
  'p.home.mytask.form.invoicestartdate': 'Billing Start Date',
  'p.home.mytask.form.invoiceenddate': 'Billing End Date',
  'p.home.mytask.form.finishstartdate': 'Finish Start Date',
  'p.home.mytask.form.finishenddate': 'Finish End Date',
  'p.home.mytask.form.projectmanager': 'Project Manager',
  'p.home.mytask.form.projectstate': 'State',
  'p.home.mytask.form.excelimport': 'Excel Import',
  'p.home.mytask.modal.excelbutton': 'Please Select Excel File',
  'p.home.mytask.menu.excelmodel': 'Download Template',
  'p.home.mytask.menu.excelimport': 'Excel Import',
  'p.excelimportres.form.keyword': 'Sale Order',
  'p.addproject.title': 'Add Project',
  'p.addproject.edit.title': 'Edit Project',
  'p.addproject.btn.add': 'Search Project',
  'p.addproject.tabs.title.commommsg': 'Common Info',
  'p.addproject.tabs.title.itemmsg': 'Item Info',
  'p.home.mytask.form.btn.updateitemstate': 'Batch set Item status',
  'p.home.mytask.columns.saleorder': 'SO',
  'p.home.mytask.columns.projectname': 'Project Name',
  'p.home.mytask.columns.handovertime': 'Project Handover Date',
  'p.home.mytask.columns.customercompanyname': 'Customer Name',
  'p.home.mytask.columns.promiseddeliverytime': 'Confirmed EXW Date',
  'p.home.mytask.columns.sale': 'Sale',
  'p.report.columns.saleorder': 'SO',
  'p.report.columns.projectmanager': 'Project Manager',
  'p.report.columns.sale': 'Sale',
  'p.report.columns.projectname': 'Project Name',
  'p.report.columns.handovertime': 'Project Handover Date',
  'p.report.columns.customercompanyname': 'Customer Name',
  'p.report.columns.promiseddeliverytime': 'Confirmed EXW Date',
  'p.report.columns.actualshipmentdate': 'Actual EXW Date',
  'p.report.columns.invoicedate': 'Billing Date',
  'p.report.columns.factory': 'Plant',
  'p.report.columns.profitcenter': 'PC',
  'p.report.columns.guaranteeperiod': 'Warranty',
  'p.excelimportres.columns.saleorder': 'SO',
  'p.excelimportres.columns.status': 'Status',
  'p.excelimportres.columns.synchronousmessages': 'Synchronous Messages',
  // -------------------------------------------------------
  'p.addproject.checkItemsColumns.checkItemType': 'Check Items Type',
  'p.addproject.checkItemsColumns.name': 'Check Items',
  'p.addproject.checkItemsColumns.status': 'Status',
  'p.addproject.checkItemsColumns.remark': 'Remark',
  'p.addproject.checkItemsColumns.status.placeholder': 'Please select status.',
  'p.addproject.checkItemsColumns.remark.placeholder': 'Please enter remarks',
  'p.addproject.itemsColumns.commitmentDate': 'Confirmed EXW Date',
  'p.addproject.itemsColumns.deliveryDate': 'Confirmed Contractual Delivery Date',
  'p.addproject.itemsColumns.invoiceDate': 'Billing Date',
  'p.addproject.itemsColumns.customerCode': 'Customer Code',
  'p.addproject.itemsColumns.projectStatus': 'Project Status',
  'p.addproject.testColumns.testType': 'Test Type',
  'p.addproject.testColumns.testDate': 'Test Date',
  'p.addproject.testColumns.remark': 'Remark',
  'p.addproject.testColumns.testDate.placeholder': 'Please select a test date',
  'p.addproject.testColumns.remark.placeholder': 'Please enter remarks',
  'p.addproject.supplyColumns.arriveData': 'Arrive Date',
  'p.addproject.supplyColumns.isRrive': 'Arrive or not',
  'p.addproject.supplyColumns.remark': 'Remark',
  'p.addproject.commommsg.basicinformation': 'Basic Information',
  'p.addproject.commommsg.saleorder': 'SO',
  'p.addproject.commommsg.projectname': 'Project Name',
  'p.addproject.commommsg.designnum': 'Design Num',
  'p.addproject.commommsg.handovertime': 'Project Handover Date',
  'p.addproject.commommsg.logisticsterms': 'Incotems',
  'p.addproject.commommsg.customerinformation': 'Customer Information',
  'p.addproject.commommsg.customercompanyname': 'Customer Name',
  'p.addproject.commommsg.customercontact': 'Customer Contact',
  'p.addproject.commommsg.customercontact.placeholder': 'Please enter customer contact',
  'p.addproject.commommsg.projectrelatedperson': 'Project stakeholders',
  'p.addproject.commommsg.projectmanager': 'Project Manager',
  'p.addproject.commommsg.sale': 'Sale',
  'p.addproject.commommsg.electricalengineer': 'Electrical Engineer',
  'p.addproject.commommsg.electricalengineer.placeholder': 'Please contact electrical engineers',
  'p.addproject.commommsg.mechanicalengineer': 'Mechanical Engineer',
  'p.addproject.commommsg.mechanicalengineer.placeholder': 'Please enter mechanical engineers',
  'p.addproject.commommsg.checkitems': 'Check Items',
  'p.addproject.commommsg.btn.settingcheckitems': 'Setting Check Items',
  'p.addproject.commommsg.btn.classificationsociety': 'Marine',
  'p.addproject.commommsg.btn.classificationsociety.placeholder': 'Please select Marine',
  'p.addproject.itemmsg.btn.updateitemstate': 'Batch set Item status',
  'p.addproject.modal.eidtitems.title': 'Edit Item',
  'p.addproject.modal.eidtitems.iteminfo': 'Item Info',
  'p.addproject.modal.eidtitems.promiseddeliverytime': 'Confirmed EXW Date',
  'p.addproject.modal.eidtitems.deliveryDate': 'Confirmed Contractual Delivery Date',
  'p.addproject.modal.eidtitems.voltage': 'Voltage',
  'p.addproject.modal.eidtitems.actualshipmentdate': 'Actual EXW Date',
  'p.addproject.modal.eidtitems.invoicedate': 'Billing Date',
  'p.addproject.modal.eidtitems.guaranteeperiod': 'Warranty',
  'p.addproject.modal.eidtitems.power': 'Power',
  'p.addproject.modal.eidtitems.seriesnumber': 'SN',
  'p.addproject.modal.eidtitems.purchaseordernum': 'PO',
  'p.addproject.modal.eidtitems.logisticsterms': 'Incotems',
  'p.addproject.modal.eidtitems.designfreezingpoint': 'FP (Manual Input)',
  'p.addproject.modal.eidtitems.designfreezingpoint.placeholder': 'Please enter the FP (Manual Input)',
  'p.addproject.modal.eidtitems.certificate': 'Certification',
  'p.addproject.modal.eidtitems.certificate.placeholder': 'Please select Certification',
  'p.addproject.modal.eidtitems.color': 'Motor Color',
  'p.addproject.modal.eidtitems.color.placeholder': 'Please enter Motor Color',
  'p.addproject.modal.eidtitems.packingrequirement': 'Packing',
  'p.addproject.modal.eidtitems.packingrequirement.placeholder': 'Please select Packing',
  'p.addproject.modal.eidtitems.projectfilepath': 'Project File Path',
  'p.addproject.modal.eidtitems.addfilepath': 'Add File Path',
  'p.addproject.modal.eidtitems.IprocessECN/ECRurl': 'iProcess ECN/ECR Url',
  'p.addproject.modal.eidtitems.checkitem.name': 'Check Items',
  'p.addproject.modal.eidtitems.settingcheckitems': 'Setting Check Items',
  'p.addproject.modal.eidtitems.starconnectiontype': 'Star Connection Type',
  'p.addproject.modal.eidtitems.starconnectiontype.placeholder': 'Select a star connection type',
  'p.addproject.modal.eidtitems.witnesstestinformation': 'Witness Test Information',
  'p.addproject.modal.eidtitems.customersupplyinformation': 'Customer Provided Information',
  'p.addproject.modal.eidtitems.addcustomersupplies': 'Add Customer Provided Parts',
  'p.addproject.modal.eidtitems.po.placeholder': 'Please enter the PO ',
  'p.addproject.modal.eidtitems.arriveDate': 'Arrive Date',
  'p.addproject.modal.eidtitems.arriveDate.placeholder': 'Please select the arrive date',
  'p.addproject.modal.eidtitems.isArrive': 'Arrive or not',
  'p.addproject.modal.eidtitems.isArrive.placeholder': 'Please select whether the arrive or not',
  'p.addproject.modal.eidtitems.remark.placeholder': 'Please enter remarks',
  //-------------------------------------------------------------------
  'p.stepandmrp.addstep': 'Add Step',
  'p.stepandmrp.addmrp': 'Add MRP',
  'p.checkitems.addstep.msg': 'Please select step.',
  'p.checkitems.addmrp.second.msg': 'MRP can only be added based on step.',
  'p.mytask.tabs.title.associatedSoPro': 'Associated SO Projects',
  'p.mytask.tabs.title.noAssociatedSoPro': 'Non-Associated SO Projects',
  'p.home.mytask.form.itemnum': 'Item',
  'p.home.mytask.form.reservationnum': 'Reservation Num',
  'p.addproject.modal.eidtitems.itemnum.placeholder': 'Please input the Item Num',
  'p.addproject.modal.eidtitems.remark.placeholder': 'Please input the Remark',
  'p.addproject.modal.eidtitems.productionplan': 'Production Plan',
  'p.addproject.planColumns.planStep': 'Production steps',
  'p.addproject.planColumns.planStartTime': 'Plan Start Time',
  'p.addproject.planColumns.planEndTime': 'Plan End Time',
  'p.addproject.planColumns.actualStartTime': 'Actual Start Time',
  'p.addproject.planColumns.actualEndTime': 'Actual End Time',
  'p.home.mytask.form.filedesc': 'Flie Describe',
  'p.home.mytask.form.filepath': 'Flie Path',
  'p.home.mytask.form.networth': 'Net Value',
  'p.home.mytask.form.currency': 'Currency',
  'p.home.mytask.form.number': 'QTY',
  'p.home.mytask.form.Pre-deliverydate': 'Pre-delivery Date',
  'p.addproject.modal.eidtitems.TVNum': 'Machine Type',
  'p.addproject.modal.eidtitems.TVNum.place': 'Please input the Machine Type',
  'p.home.mytask.form.currency.place': 'Please select the currency',
  'p.home.mytask.form.Pre-deliverydate.place': 'Please select the Pre-deliverydate',
  'p.home.mytask.form.projectstate.place': 'Please select the State',
  'p.addproject.modal.eidtitems.addWitnessTest': 'Add Witness Test',
  'p.addproject.testColumns.isCompleted': 'Is test Completed',
  'p.home.mytask.form.contractNumber': 'Contract Number',
  'p.addproject.commommsg.projectname.Request': 'Project name is request.',
  'p.addproject.commommsg.reservationNum.Request': 'reservation number is request.',
  'p.addproject.commommsg.projectManager.Request': 'project manager is request.',
  'p.addproject.commommsg.itemNum.Request': 'Item Num is request.',
  'p.addproject.commommsg.projectState.Request': 'State is request.',
  'p.mailmodel.name': 'Name',
  'p.mailmodel.title': 'Title',
  'p.mailmodel.modelContext': 'Mail Model',
  'p.addproject.info.checkitem.msg': 'The status of the project check item is required',
  'p.addproject.item.checkitem.msg': 'The status of item check item is required',
  'p.addproject.modal.eidtitems.createdBy': 'Created By',
  'p.addproject.modal.eidtitems.createdTime': 'Created Time',
  'p.addproject.testColumns.isSo': 'Associate sales order',
  'p.addproject.testColumns.IsMarine': 'Is Marine',
  'p.addproject.itemsColumns.abacuscode': 'ABACUS Code',
  'p.addproject.modal.eidtitems.payment': 'Payment',
  'p.home.mytask.form.industry': 'Industry',
  'p.home.mytask.form.application': 'Application',
  'p.report.columns.netRenminbi': 'ORDER REV(CNY)',
  'report.waring.column.isnull': 'Please set the display field',
  'p.addproject.commommsg.items.Request': 'Please add Item data',
  'p.addproject.modal.delete.title': 'Delete',
  'p.addproject.modal.deleteInfo': 'Delete Project',
  'p.addproject.modal.deleteItem': 'Only Delete Project Item',
  'p.addproject.modal.delete.message': 'Deleted data cannot be recovered, please confirm the operation !',
  'p.addproject.modal.itemnum.message': "Item num does not match !",
  'p.addproject.uploadTitle': 'Upload File',
  'p.addproject.Customerdrawings': 'Customer Drawings',
  'p.addproject.certificate': 'Certification',
  'p.addproject.QualityDocumentation': 'Quality Documents',
  'p.addproject.other': 'Others',
  'p.filedmodelform.title': 'Display Setting List',
  'p.filedmodelform.name': 'Name',
  'p.filedmodelform.isDefault': 'Is Default Display',
  'p.filedmodelform.operation': 'Operation',
  'p.ReportFieldForm.title': 'Please select display fields',
  'p.report.columns.item': 'Item',
  'p.report.columns.default.show.fiels': 'Display fields by default',
  'p.addproject.modal.eidtitems.uploadfile': 'Upload File',
  'p.addproject.modal.eidtitems.addprojectfilepath': 'View Project File ',
  'p.projectfilepathdetail.title': 'Project File Path',
  'p.report.columns.customerContact': 'Contract Number',
  'p.backup.form.status': 'Status',
  'p.backup.form.startdate': 'Effective Start Date',
  'p.backup.form.enddate': 'Effective End Date',
  'p.backup.form.effectivedate': 'Effective Date',
  'p.backup.form.backuppmname':'Backup Project Manager',
  'p.home.mytask.form.sapUpdateTime': 'SAP Update Time',
};
export default messages;
