const messages = {
  'menu.setting': '设置',
  'menu.menu': '菜单',
  'menu.role': '角色',
  'menu.message': '邮件',
  'menu.appconfig': '配置',
  'menu.user': '用户',
  'menu.home': '主页',
  'menu.report': '报表',
  'menu.excelimportres': 'Excel导入结果',
  'menu.checkitems': '检查项',
  'menu.masterdata': '基础数据',
  'menu.othermasterdata': '其他基础数据',
  'menu.stepandmrp': '步骤和MRP',
  'menu.mailmodel': '邮件模板',
  'menu.promanagerbackup': '项目经理备份',
};
export default messages;
