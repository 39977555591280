import { useReducer } from 'react';
import { debugEnable } from '../utils/utils';

function addLog(dispatch) {
  const next = dispatch;
  return action => {
    console.log('[useCustomReducer]dispatching', action);
    let result = next(action);
    return result;
  };
}
function addThunk(dispatch) {
  const next = dispatch;
  return action => {
    if (typeof action === 'function') {
      action(dispatch);
    } else {
      return next(action);
    }
  };
}

// 重写reducer，使dispatch支持function（类redux-thunk）
export function useCustomReducer(reducer, initialState) {
  let [state, dispatch] = useReducer(reducer, initialState);
  // log
  if (debugEnable) dispatch = addLog(dispatch);
  // thunk
  dispatch = addThunk(dispatch);

  return [state, dispatch];
}

// 实现异步actionCreator, 类redux toolkit中的createAsyncThunk
export function createAsyncAction(type, asyncAction) {
  const pending = `${type}/pending`;
  const fulfilled = `${type}/fulfilled`;
  const rejected = `${type}/rejected`;

  const actionCreator = arg => {
    return dispatch => {
      dispatch({ type: pending });
      asyncAction(arg)
        .then(res => dispatch({ type: fulfilled, payload: res }))
        .catch(error => dispatch({ type: rejected, payload: error }));
    };
  };
  actionCreator.pending = pending;
  actionCreator.fulfilled = fulfilled;
  actionCreator.rejected = rejected;
  return actionCreator;
}

export function createAction(type, payload) {
  return { type, payload };
}
