import React from 'react';
import './LayoutPlain.less';
import { FormattedMessage } from 'react-intl';

function LayoutPlain({ children }) {
  let year = new Date().getFullYear();
  return (
    <div className="layout-plain">
      <div className="app-header-container">
        <div className="app-header-wrapper">
          <div className="abb-bar">
            <FormattedMessage id="layout.appname" />
          </div>
        </div>
      </div>
      <div className="app-body-container">
        <div className="app-body-wrapper">{children}</div>
      </div>
      <div className="app-footer-container">
        <div className="app-footer-wrapper">&copy; {year} ABB</div>
      </div>
    </div>
  );
}

export default LayoutPlain;
