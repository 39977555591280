import React, { useState, useEffect } from 'react';
import { Button, Drawer, Space, Table, message, Popconfirm } from 'antd';
import { MessageOutlined, GlobalOutlined } from '@ant-design/icons';
import { useIntl, FormattedMessage } from 'react-intl';
import styles from './ExcelImportNotice.less';
import { getAll, del } from '@/api/importrecord';
import ExcelImportRes from '../features/home/ExcelImportRes';

const ExcelImportNotice = () => {
  const intl = useIntl();
  const [visible, setVisible] = useState(false);
  const [tableSource, setTableSource] = useState([]);
  const [editVisible, setEditVisible] = useState(false);
  const [recordID, setRecordID] = useState(null);

  useEffect(() => {
    if (visible) {
      getAll().then(res => {
        setTableSource(res);
      });
    }
  }, [visible]);

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const showDetailClick = id => {
    setRecordID(id);
    setEditVisible(true);
  };
  const handleItemSave = () => {
    setEditVisible(false);
  };
  const handleEditCancel = () => {
    setEditVisible(false);
  };
  const deleteClick = id => {
    del({ id }).then(() => {
      message.success('Success');
      getAll().then(res => {
        setTableSource(res);
      });
    });
  };

  const columns = [
    {
      title: intl.formatMessage({ id: 'p.home.excelimport.table.name' }),
      dataIndex: 'fileName',
      key: 'fileName',
    },
    {
      title: intl.formatMessage({ id: 'p.home.excelimport.table.time' }),
      dataIndex: 'importTime',
      key: 'importTime',
      render: importTime => {
        return importTime != null
          ? importTime.substring(0, 19).replace('T', ' ')
          : '';
      },
    },
    {
      title: intl.formatMessage({ id: 'p.home.excelimport.table.result' }),
      dataIndex: 'result',
      key: 'result',
    },
    {
      render: (text, item) => (
        <Space size="middle">
          <Button type="link" onClick={() => showDetailClick(item.id)}>
            <FormattedMessage id="common.see" />
          </Button>
          <Popconfirm
            title={intl.formatMessage(
              { id: 'common.delcfm' },
              { value: item.email }
            )}
            okText={intl.formatMessage({ id: 'common.yes' })}
            cancelText={intl.formatMessage({ id: 'common.no' })}
            placement="topRight"
            onConfirm={() => deleteClick(item.id)}
          >
            <Button type="link">
              <FormattedMessage id="common.delete" />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  return (
    <div className="importDiv">
      <MessageOutlined />
      <Button type="text" onClick={showDrawer}>
        {intl.formatMessage({ id: 'p.home.excelimport.res.title' })}
      </Button>
      <Drawer
        title={intl.formatMessage({ id: 'p.home.excelimport.res.title' })}
        placement="right"
        size="large"
        onClose={onClose}
        visible={visible}
      >
        <Table
          columns={columns}
          dataSource={tableSource}
          pagination={false}
          scroll={{ y: 750 }}
        />
      </Drawer>
      <ExcelImportRes
        key={Math.random()}
        editVisible={editVisible}
        recordID={recordID}
        handleItemSave={handleItemSave}
        handleEditCancel={handleEditCancel}
      />
    </div>
  );
};
export default ExcelImportNotice;
