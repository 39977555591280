import { debugEnable } from '../utils/utils';

export function handleError(e, msg, callback) {
  if (debugEnable) console.log('[handleError]:', e, msg);
  if (!callback) {
    callback = alert;
  }
  if (e) {
    if (e.status === 400 && e.data?.title) {
      callback(e.data?.title);
      return;
    }
    if (e.status === 401) {
      callback('登录信息已过期，请重新登录');
      return;
    }
  }
  callback(msg || 'Error');
}
