import React, { useState, useEffect } from 'react';
import { Space, Button, Popconfirm, Form, Input, Select, message, Modal } from 'antd';
import { SearchOutlined, ExceptionOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { handleError } from '@/utils/handleError';
import PageHeader from '@/components/PageHeader';
import PagedTable from '@/components/PagedTable';
import moment from 'moment';
import { getSyncDataByRecordID, getSyncDataByRecordIdExport } from '@/api/syncdatalog';

// excel
import * as XLSX from 'xlsx';
import ExportJsonExcel from 'js-export-excel';

const ExcelImportRes = ({
  editVisible,
  recordID,
  handleItemSave,
  handleEditCancel
}) => {
  const intl = useIntl();
  //---------------------- state
  // search form
  const [form] = Form.useForm();
  // search form values. query params.
  const [params, setParams] = useState();
  //---------------------- effects
  // Query data after entering this page. 进入此页面后查询数据
  useEffect(() => {
    form.setFieldsValue({
      recordID: recordID
    });
    form.submit();
  }, [form]);
  //---------------------- events
  // search
  const handleSearch = values => {
    setParams(values);
  };
  //DateForm
  let DateFormStr = date => {
    let nowDate = new Date(date);
    return (nowDate =
      `${nowDate.getFullYear()}` +
      '-' +
      `${nowDate.getMonth() + 1 >= 10
        ? nowDate.getMonth() + 1
        : '0' + (nowDate.getMonth() + 1)
      }` +
      '-' +
      `${nowDate.getDate() >= 10 ? nowDate.getDate() : '0' + nowDate.getDate()
      }`);
  };
  const handleExport = () => {
    let exportParams = form.getFieldsValue();
    if (exportParams != null && exportParams.saleOrder == undefined) {
      exportParams.saleOrder = '';
    }
    getSyncDataByRecordIdExport(exportParams).then(res => {
      let dataTable = [];
      res.map(item => {
        dataTable.push(item);
      });
      let options = {};
      options.fileName = `ImportResult_${DateFormStr(new Date())}_${moment(
        new Date()
      ).unix()}`;
      options.datas = [
        {
          sheetData: dataTable,
          sheetName: 'sheet1',
          sheetFilter: [
            'saleOrder',
            'result',
            'description',
          ],
          sheetHeader: [
            'saleOrder',
            'result',
            'description',
          ],
          columnWidths: [10, 10, 30, ],
        },
      ];
      let toExcel = new ExportJsonExcel(options);
      toExcel.saveExcel();
    });
  };
  //---------------------- view
  const columns = [
    {
      title: intl.formatMessage({ id: 'p.excelimportres.columns.saleorder' }),
      dataIndex: 'saleOrder',
    },
    {
      title: intl.formatMessage({ id: 'p.excelimportres.columns.status' }),
      dataIndex: 'result',
    },
    {
      title: intl.formatMessage({ id: 'p.excelimportres.columns.synchronousmessages' }),
      render: (item) => { return item.result == "Fail" ? item.description : item.result; },
    },
  ];

  return (
    <Modal
      title={intl.formatMessage({ id: 'p.addproject.modal.eidtitems.title' })}
      visible={editVisible}
      onOk={handleItemSave}
      onCancel={handleEditCancel}
      style={{ minWidth: '1280px' }}
    >
      {/*<PageHeader title={intl.formatMessage({ id: 'menu.excelimportres' })} />*/}
      <Form
        layout="inline"
        className="small-space margin-bottom"
        form={form}
        onFinish={handleSearch}
      >
        <Form.Item name="recordID">
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="saleOrder">
          <Input
            placeholder={intl.formatMessage({ id: 'p.addproject.commommsg.saleorder' })}
            style={{ width: 280 }}
            allowClear={true}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            <SearchOutlined />
            {intl.formatMessage({ id: 'common.search' })}
          </Button>
        </Form.Item>
        <Form.Item>
          <Button type="button" onClick={handleExport}>
            <ExceptionOutlined />
            {intl.formatMessage({ id: 'common.exportexcel' })}
          </Button>
        </Form.Item>
      </Form>
      {params && (
        <PagedTable
          columns={columns}
          request={getSyncDataByRecordID}
          params={params}
          rowKey="id"
        />
      )}
    </Modal>
  );
}
export default ExcelImportRes;